<template>
  <div
    class="service-create create-form"
    v-if="getPermission('service:create')"
  >
    <v-form
      ref="serviceForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-container fluid class="white-background main-create-page">
            <div class="p-5 pb-0">
              <v-row>
                <v-col cols="6" class="pt-0">
                  <h1 class="form-title d-flex">
                    <template v-if="serviceId">Update</template>
                    <template v-else>Create</template> Service
                  </h1>
                </v-col>
                <v-col cols="6" class="pt-0 text-right">
                  <v-btn
                    :disabled="!formValid || formLoading"
                    :loading="formLoading"
                    class="mx-2 custom-bold-button text-white"
                    color="cyan"
                    v-on:click="onSubmit"
                  >
                    <template v-if="serviceId">Update</template>
                    <template v-else>Save</template> Service
                  </v-btn>
                  <v-btn
                    v-on:click="goBack"
                    :disabled="formLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                    outlined
                    >Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <ServiceCreate
              :productId="serviceId"
              :barcodeData="barcode"
              :productCreateData="serviceCreate"
              v-on:saveProduct="updateService"
            ></ServiceCreate>
          </v-container>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ServiceMixin from "@/core/plugins/product-mixin";
import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";
import ServiceCreate from "@/view/pages/service/partials/Create.vue";

export default {
  mixins: [CommonMixin, ServiceMixin],
  name: "service-create",
  title: "Create Service",
  data() {
    return {
      barcode: null,
      barcodeDialog: false,
      barcodeSetting: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
      serviceCreate: null,
      serviceId: null,
      duplicateId: null,
    };
  },
  components: {
    ServiceCreate,
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  methods: {
    updateService(param) {
      this.serviceCreate = param;
    },
    getService(serviceId) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (serviceId && serviceId > 0) {
          _this.$store
            .dispatch(GET, { url: "service/" + serviceId })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });
    },
    onSubmit() {
      const _this = this;
      if (!_this.$refs.serviceForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "service";

      if (_this.serviceId && _this.serviceId > 0) {
        requestType = PUT;
        requestURL = "service/" + _this.serviceId;
      }

      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.serviceCreate })
        .then((response) => {
          _this.serviceId = response.data ? response.data.id : null;
          _this.$router.push(
            _this.getDefaultRoute("service", {
              query: {
                status: "all",
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    loadContent() {
      const _this = this;
      _this
        .getService(_this.serviceId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Service", route: "service" },
              { title: "Update" },
            ]);
            _this.barcode = response.barcode;

            let categoryId = null;

            if (response.category) {
              categoryId = response.category.id;
            }

            _this.serviceCreate = {
              name: response.name,
              reference: response.reference,
              description: response.description,
              category: categoryId,
              product_type: response.product_type,
              images: response.images,
              product_images: response.product_images,
              sellable: response.is_sellable,
              confirmed: response.is_confirmed,
              tax: response.tax ? response.tax.id : null,
              tax_exemption: response.tax_exemption
                ? response.tax_exemption.id
                : null,
              taxable: response.is_taxable,
              charges: response.charges,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    loadDuplicateContent() {
      const _this = this;
      _this
        .getService(_this.duplicateId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.barcode = response.barcode;
            _this.serviceCreate = {
              name: response.name,
              reference: response.reference,
              description: response.description,
              product_type: response.product_type,
              images: response.images,
              product_images: response.product_images,
              sellable: response.is_sellable,
              confirmed: response.is_confirmed,
              tax: response.tax ? response.tax.id : null,
              tax_exemption: response.tax_exemption
                ? response.tax_exemption.id
                : null,
              taxable: response.is_taxable,
              charges: response.charges,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  created() {
    const _this = this;
    _this.serviceId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Service", route: "service" },
      { title: "Create" },
    ]);

    if (
      _this.$route.name == "admin.service.update" ||
      _this.$route.name == "engineer.service.update"
    ) {
      _this.updateMode = true;

      if (_this.serviceId <= 0) {
        _this.$router.go(-1);
      }

      _this.loadContent();
    }

    if (_this.duplicateId > 0) {
      _this.loadDuplicateContent();
    }
  },
};
</script>
